import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useMemo } from "react";
import { QuadTree, Rectangle, Point } from "./QuadTree";
import { PoissonDiskSampling } from "../utils/PoissonDiskSampling";
import gsap from "gsap";

const Hero = () => {
  const BASE_MIN_SPEED = 0.4;
  const INITIAL_SPEED_MULTIPLIER = 2.0;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const CURSOR_HIDE_DELAY = 2000;
  const cursorRef = useRef({
    x: 0,
    y: 0,
    targetX: 0,
    targetY: 0,
    size: 20,
    trail: [] as { x: number; y: number; size: number }[],
  });
  const particlesRef = useRef<
    Array<{
      x: number;
      y: number;
      vx: number;
      vy: number;
      size: number;
      originalX: number;
      originalY: number;
    }>
  >([]);
  const animationFrameRef = useRef<number>();
  const startTimeRef = useRef<number>(Date.now());
  const shouldDestructRef = useRef(false);
  const isResettingRef = useRef(false);
  const cursorTimeoutRef = useRef<number>();
  const mouseRef = useRef({
    x: 0,
    y: 0,
    isActive: false,
    lastX: 0,
    lastY: 0,
  });

  const initializeParticles = useCallback((canvas: HTMLCanvasElement) => {
    const tempCanvas = document.createElement("canvas");
    const tempCtx = tempCanvas.getContext("2d");
    if (!tempCtx) return;

    tempCanvas.width = canvas.width;
    tempCanvas.height = canvas.height;

    const fontSize = Math.min(canvas.width / 8, 180);
    tempCtx.fillStyle = "#000000";
    tempCtx.font = `${fontSize}px sans-serif`;
    tempCtx.textAlign = "center";
    tempCtx.textBaseline = "middle";
    tempCtx.fillText("MARANDAT", tempCanvas.width / 2, tempCanvas.height / 2);

    const imageData = tempCtx.getImageData(
      0,
      0,
      tempCanvas.width,
      tempCanvas.height,
    );
    const particles: typeof particlesRef.current = [];

    const mask: boolean[][] = [];
    for (let y = 0; y < tempCanvas.height; y++) {
      mask[y] = [];
      for (let x = 0; x < tempCanvas.width; x++) {
        const alpha = imageData.data[(y * tempCanvas.width + x) * 4 + 3];
        mask[y][x] = alpha > 128;
      }
    }

    const minDistance = 3;
    const sampler = new PoissonDiskSampling(
      tempCanvas.width,
      tempCanvas.height,
      minDistance,
    );
    const points = sampler.generate([
      tempCanvas.width / 2,
      tempCanvas.height / 2,
    ]);

    points.forEach(([x, y]) => {
      const xInt = Math.floor(x);
      const yInt = Math.floor(y);
      if (
        xInt >= 0 &&
        xInt < tempCanvas.width &&
        yInt >= 0 &&
        yInt < tempCanvas.height &&
        mask[yInt][xInt]
      ) {
        const angle = Math.random() * Math.PI * 2;
        const initialSpeed = BASE_MIN_SPEED * INITIAL_SPEED_MULTIPLIER;
        particles.push({
          x: xInt,
          y: yInt,
          originalX: xInt,
          originalY: yInt,
          vx: Math.cos(angle) * initialSpeed,
          vy: Math.sin(angle) * initialSpeed,
          size: 2,
        });
      }
    });

    particlesRef.current = particles;
  }, []);

  const resetParticles = useCallback(() => {
    shouldDestructRef.current = false;
    if (isResettingRef.current) return;
    isResettingRef.current = true;

    const tl = gsap.timeline({
      onComplete: () => {
        const destructTimeline = gsap.timeline({
          delay: 1,
          onComplete: () => {
            shouldDestructRef.current = true;
            particlesRef.current.forEach((particle) => {
              const angle = Math.random() * Math.PI * 2;
              const resetSpeed = BASE_MIN_SPEED * INITIAL_SPEED_MULTIPLIER;
              particle.vx = Math.cos(angle) * resetSpeed;
              particle.vy = Math.sin(angle) * resetSpeed;
            });
          },
        });
        startTimeRef.current = Date.now();
        destructTimeline.play();
        isResettingRef.current = false;
      },
    });

    particlesRef.current.forEach((particle) => {
      tl.to(
        particle,
        {
          x: particle.originalX,
          y: particle.originalY,
          duration: 1.5,
          ease: "power2.out",
        },
        0,
      );
    });
  }, []);

  const updateCursor = useCallback(() => {
    const cursor = cursorRef.current;
    const dx = cursor.targetX - cursor.x;
    const dy = cursor.targetY - cursor.y;

    cursor.x += dx * 0.1;
    cursor.y += dy * 0.1;

    cursor.trail.unshift({
      x: cursor.x,
      y: cursor.y,
      size: cursor.size * 0.8,
    });
    if (cursor.trail.length > 5) {
      cursor.trail.pop();
    }
  }, []);

  const handleCollisions = useCallback(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;

    const quadTree = new QuadTree(
      new Rectangle(
        canvas.width / 2,
        canvas.height / 2,
        canvas.width / 2,
        canvas.height / 2,
      ),
      4,
    );

    particlesRef.current.forEach((particle) => {
      quadTree.insert(new Point(particle.x, particle.y, particle));
    });

    if (mouseRef.current.isActive) {
      const cursor = cursorRef.current;
      particlesRef.current.forEach((particle) => {
        const dx = particle.x - cursor.x;
        const dy = particle.y - cursor.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        const minDistance = cursor.size + particle.size;

        if (distance < minDistance) {
          const angle = Math.atan2(dy, dx);
          const pushForce = (minDistance - distance) * 0.1;
          particle.x = cursor.x + Math.cos(angle) * minDistance;
          particle.y = cursor.y + Math.sin(angle) * minDistance;
          particle.vx += Math.cos(angle) * pushForce;
          particle.vy += Math.sin(angle) * pushForce;
        }
      });
    }

    particlesRef.current.forEach((particle) => {
      const range = new Rectangle(particle.x, particle.y, 10, 10);
      const points = quadTree.query(range);

      points.forEach((point) => {
        const other = point.data;
        if (other === particle) return;

        const dx = other.x - particle.x;
        const dy = other.y - particle.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < particle.size + other.size) {
          const angle = Math.atan2(dy, dx);
          const sin = Math.sin(angle);
          const cos = Math.cos(angle);

          const vx1 = particle.vx * cos + particle.vy * sin;
          const vy1 = particle.vy * cos - particle.vx * sin;
          const vx2 = other.vx * cos + other.vy * sin;
          const vy2 = other.vy * cos - other.vx * sin;

          const m1 = particle.size;
          const m2 = other.size;
          const u1 = (vx1 * (m1 - m2)) / (m1 + m2) + (vx2 * 2 * m2) / (m1 + m2);
          const u2 = (vx2 * (m2 - m1)) / (m1 + m2) + (vx1 * 2 * m1) / (m1 + m2);

          particle.vx = u1 * cos - vy1 * sin;
          particle.vy = vy1 * cos + u1 * sin;
          other.vx = u2 * cos - vy2 * sin;
          other.vy = vy2 * cos + u2 * sin;

          const overlap = (particle.size + other.size - distance) / 2;
          particle.x -= overlap * cos;
          particle.y -= overlap * sin;
          other.x += overlap * cos;
          other.y += overlap * sin;
        }
      });
    });
  }, []);

  const animate = useCallback(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    ctx.fillStyle = "rgba(255, 255, 255, 0.1)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    updateCursor();

    if (mouseRef.current.isActive) {
      cursorRef.current.trail.forEach((point, index) => {
        const alpha = 1 - index / cursorRef.current.trail.length;
        ctx.fillStyle = `rgba(51, 51, 51, ${alpha * 0.2})`;
        ctx.beginPath();
        ctx.arc(point.x, point.y, point.size, 0, Math.PI * 2);
        ctx.fill();
      });

      ctx.fillStyle = "rgba(51, 51, 51, 0.3)";
      ctx.beginPath();
      ctx.arc(
        cursorRef.current.x,
        cursorRef.current.y,
        cursorRef.current.size,
        0,
        Math.PI * 2,
      );
      ctx.fill();
    }

    handleCollisions();

    particlesRef.current.forEach((particle) => {
      if (!isResettingRef.current && shouldDestructRef.current) {
        const minSpeed = BASE_MIN_SPEED;
        const maxSpeed = 2;

        if (mouseRef.current.isActive) {
          const dx = cursorRef.current.x - particle.x;
          const dy = cursorRef.current.y - particle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          const pushRadius = 100;

          if (distance < pushRadius) {
            const force = (1 - distance / pushRadius) * 0.5;
            particle.vx -= (dx / distance) * force;
            particle.vy -= (dy / distance) * force;
          }
        }

        particle.x += particle.vx;
        particle.y += particle.vy;

        const currentSpeed = Math.sqrt(
          particle.vx * particle.vx + particle.vy * particle.vy,
        );
        if (currentSpeed > minSpeed) {
          const speed = Math.sqrt(
            particle.vx * particle.vx + particle.vy * particle.vy,
          );
          const dynamicFriction = Math.max(
            0.995,
            1 - 0.005 / Math.max(speed, 0.1),
          );
          particle.vx *= dynamicFriction;
          particle.vy *= dynamicFriction;
        }

        const newSpeed = Math.sqrt(
          particle.vx * particle.vx + particle.vy * particle.vy,
        );
        if (newSpeed < minSpeed) {
          const scale = minSpeed / newSpeed;
          particle.vx *= scale;
          particle.vy *= scale;
        }

        if (newSpeed > maxSpeed) {
          const scale = maxSpeed / newSpeed;
          particle.vx *= scale;
          particle.vy *= scale;
        }

        if (particle.x <= 0 || particle.x >= canvas.width) {
          particle.vx *= -0.8;
          particle.x = Math.max(0, Math.min(canvas.width, particle.x));
        }
        if (particle.y <= 0 || particle.y >= canvas.height) {
          particle.vy *= -0.8;
          particle.y = Math.max(0, Math.min(canvas.height, particle.y));
        }
      }

      ctx.fillStyle = "#333333";
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fill();
    });

    animationFrameRef.current = requestAnimationFrame(animate);
  }, [handleCollisions, updateCursor]);

  const resetCursorTimeout = useCallback(() => {
    if (cursorTimeoutRef.current) {
      window.clearTimeout(cursorTimeoutRef.current);
    }

    cursorTimeoutRef.current = window.setTimeout(() => {
      mouseRef.current.isActive = false;
    }, CURSOR_HIDE_DELAY);
  }, []);

  const handlePointerMove = useCallback(
    (e: TouchEvent | MouseEvent) => {
      if (!canvasRef.current) return;
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x =
        "touches" in e
          ? e.touches[0].clientX - rect.left
          : (e as MouseEvent).clientX - rect.left;
      const y =
        "touches" in e
          ? e.touches[0].clientY - rect.top
          : (e as MouseEvent).clientY - rect.top;

      resetCursorTimeout();

      mouseRef.current = {
        lastX: mouseRef.current.x,
        lastY: mouseRef.current.y,
        x,
        y,
        isActive: true,
      };

      cursorRef.current.targetX = x;
      cursorRef.current.targetY = y;
    },
    [resetCursorTimeout],
  );

  const handlePointerEnd = useCallback(() => {
    mouseRef.current.isActive = false;
  }, []);

  const resize = useCallback(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    initializeParticles(canvas);
  }, [initializeParticles]);

  useEffect(() => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;

    window.addEventListener("resize", resize);
    canvas.addEventListener("click", resetParticles);
    canvas.addEventListener("mousemove", handlePointerMove);
    canvas.addEventListener("mouseup", handlePointerEnd);
    canvas.addEventListener("touchmove", handlePointerMove);
    canvas.addEventListener("touchend", handlePointerEnd);
    resize();

    const initialDestructTimeline = gsap.timeline({
      delay: 1,
      onComplete: () => {
        shouldDestructRef.current = true;
      },
    });
    initialDestructTimeline.play();
    animate();

    return () => {
      window.removeEventListener("resize", resize);
      canvas.removeEventListener("click", resetParticles);
      canvas.removeEventListener("mousemove", handlePointerMove);
      canvas.removeEventListener("mouseup", handlePointerEnd);
      canvas.removeEventListener("touchmove", handlePointerMove);
      canvas.removeEventListener("touchend", handlePointerEnd);
      if (cursorTimeoutRef.current) {
        window.clearTimeout(cursorTimeoutRef.current);
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [animate, handlePointerEnd, handlePointerMove, resetParticles, resize]);

  return (
    <div className="relative w-full h-screen bg-white overflow-hidden">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2 }}
        className="absolute inset-0"
      >
        <canvas
          ref={canvasRef}
          className="absolute inset-0 w-full h-full touch-none cursor-none"
        />
      </motion.div>
    </div>
  );
};

export default Hero;
