export class PoissonDiskSampling {
  private width: number;
  private height: number;
  private radius: number;
  private k: number;
  private grid: (number[] | undefined)[][];
  private cellSize: number;
  private points: number[][];
  private active: number[][];

  constructor(width: number, height: number, radius: number, k = 30) {
    this.width = width;
    this.height = height;
    this.radius = radius;
    this.k = k;
    this.cellSize = radius / Math.sqrt(2);
    this.grid = [];
    this.points = [];
    this.active = [];

    const cols = Math.ceil(width / this.cellSize);
    const rows = Math.ceil(height / this.cellSize);

    for (let i = 0; i < cols; i++) {
      this.grid[i] = [];
      for (let j = 0; j < rows; j++) {
        this.grid[i][j] = undefined;
      }
    }
  }

  private distance(a: number[], b: number[]): number {
    const dx = a[0] - b[0];
    const dy = a[1] - b[1];
    return Math.sqrt(dx * dx + dy * dy);
  }

  private inNeighborhood(point: number[]): boolean {
    const col = Math.floor(point[0] / this.cellSize);
    const row = Math.floor(point[1] / this.cellSize);

    const searchRadius = 2;
    for (let i = -searchRadius; i <= searchRadius; i++) {
      for (let j = -searchRadius; j <= searchRadius; j++) {
        const newCol = col + i;
        const newRow = row + j;
        if (
          newCol >= 0 &&
          newCol < this.grid.length &&
          newRow >= 0 &&
          newRow < this.grid[0].length
        ) {
          const neighbor = this.grid[newCol][newRow];
          if (neighbor) {
            if (this.distance(point, neighbor) < this.radius) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  private addPoint(point: number[]): void {
    const col = Math.floor(point[0] / this.cellSize);
    const row = Math.floor(point[1] / this.cellSize);
    this.grid[col][row] = point;
    this.points.push(point);
    this.active.push(point);
  }

  generate(initialPoint?: number[]): number[][] {
    if (!initialPoint) {
      initialPoint = [Math.random() * this.width, Math.random() * this.height];
    }
    this.addPoint(initialPoint);

    while (this.active.length > 0) {
      const randomIndex = Math.floor(Math.random() * this.active.length);
      const point = this.active[randomIndex];
      let found = false;

      for (let i = 0; i < this.k; i++) {
        const angle = Math.random() * Math.PI * 2;
        const r = Math.random() * this.radius + this.radius;
        const newPoint = [
          point[0] + Math.cos(angle) * r,
          point[1] + Math.sin(angle) * r,
        ];

        if (
          newPoint[0] >= 0 &&
          newPoint[0] < this.width &&
          newPoint[1] >= 0 &&
          newPoint[1] < this.height &&
          !this.inNeighborhood(newPoint)
        ) {
          this.addPoint(newPoint);
          found = true;
        }
      }

      if (!found) {
        this.active.splice(randomIndex, 1);
      }
    }

    return this.points;
  }
}
